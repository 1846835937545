import { configureStore } from "@reduxjs/toolkit";
import { reguestSlice, reguestGetSlice, reguestPlanSlice, reguestRecipeIdSlice, reguestLibrarySlice, reguestUsersSlice } from './reguestSlice'
import IngredientsSlise from './IngredientsSlise'

const store = configureStore({
  reducer: {
    ingredients: reguestSlice.reducer,
    getRecipeId: reguestRecipeIdSlice.reducer,
    //addrecipe: reguestPostSlice.reducer,
    recipes: reguestGetSlice.reducer,
    users: reguestUsersSlice.reducer,
    todos: IngredientsSlise,
    lib: reguestLibrarySlice.reducer,
    plans: reguestPlanSlice.reducer,
  }
  });

export default store;
