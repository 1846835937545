//Инпуты с возможностью множественного выбора

import React from 'react';

export function InputRadiobox (props) {
  const item = {...props.item}
  const name = item.nameEn;
  const [checked, setChecked] = React.useState(item.boolean);
 
  function handleChange(e) {
    setChecked(!checked)
    item.boolean = !checked
    props.onChange(item)
 }

  return (
    <label className='meals__label' htmlFor={name}>
      <input type="checkbox"  checked={checked} className='meals__checkbox' onChange={handleChange} name={name} id={name} />
      <span className="visible-checkbox item__link"></span>
      {item.nameRu}
    </label>
  )
}

