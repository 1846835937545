//Разметка Добавления Брендов и фасовки в базу

import React from 'react';
import { ItemIndex, AddButton, InputMeasure} from '../'

export function BrandCard (props) {
  const values= props.values
  return (
    <form className="form" onSubmit={props.onSubmit}> 
  
      <InputMeasure 
        type= 'text'
        value={values.nameRu}
        name='nameRu'
        id='nameRu'
        nameru='Русское название'
        placeholder='введите значение'
        onChange={props.onChange}
      />
      <InputMeasure 
        type= 'text'
        value={values.nameEn}
        name='nameEn'
        id='nameEn'
        nameru='Английское название'
        placeholder='введите значение'
        onChange={props.onChange}
      />
      <InputMeasure 
        type= 'textarea'
        value={values.desc}
        name='desc'
        id='desc'
        nameru='Описание'
        placeholder='введите значение'
        onChange={props.onChange}
      />
      <InputMeasure 
        type= 'file'
        value={values.image}
        name='image'
        id='image'
        nameru='Фото'
        placeholder='введите значение'
        onChange={props.onChange}
      />
      <div>
      {props.arrPacking && 
          props.arrPacking.map((item, index)=>(
            <ItemIndex 
            handleDelete={props.handleDelete}
            item={item.weight}
            key={index}
            />  
          ))}
      <InputMeasure 
        type= 'number'
        value={props.packing}
        name='packing'
        id='packing'
        nameru='Фасовка'
        placeholder='введите значение'
        onChange={props.handleChangePacking}
      />
      <AddButton 
        type='button'
        title='Добавить фасовку'
        onClick={props.onClick}
      />
      </div>
    
      <AddButton 
        type='submit'
        title={props.title}
      />
    </form>
  )
}


