import {useState, useCallback} from 'react';


export const AutoComplete = (props) => {
    function handleAutoText() {
      props.onClick(props.item)
    }
    return (
      <>
      {
        props.name === 'search-brands' ?
        <p className='add-recipe__autocomplete_text' onClick={handleAutoText}>{props.item.nameRu}</p>
        :  <p className='add-recipe__autocomplete_text' onClick={handleAutoText}>{props.item.name} {props.item.trait}</p>
      }
      </>
     
    )
  }
 
//Фильтр по поисковому слову в объекте
export function filtrSearchArr(arr, keyword, name) {
  const keywordSearch = keyword.toLowerCase();
  const NewArray = arr.filter((item) => {
    if(name === 'search-brands') {
      return item.nameRu.toLowerCase().includes(keywordSearch)
    } else {
      return item.name.toLowerCase().includes(keywordSearch)
    }
   
  })
  return NewArray
}

//Фильтр по поисковому слову в массиве
export function filtrSearchItem(arr, keyword) {
  const keywordSearch = keyword.toLowerCase();
  const NewArray = arr.filter((item) => {
    return item.toLowerCase().includes(keywordSearch)
  })
  return NewArray
}


export function sortCategory(arr) {
  let news = [...arr]
  return news.sort(function(a, b){
    return a.category === b.category ? 0 : a.category < b.category ? -1 : 1;
  })
}

export function sortName(arr) {
  let news = [...arr]
  return news.sort(function(a, b){
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  })
}

export function sortArrDate(arr) {
  let newarr =[]
  for (let i = 0; i < arr.length; i++) {
    newarr.unshift(arr[i])
  }
  return newarr 
}

//Открытие и закрытие модальных окон
export function OpenModal(namevalues) {
  const [isOpen, setIsOpen] = useState(false)
  const [editData, setEditData] =useState(false)
  
  const handleOpen = () => {
    setIsOpen(true)
  };
  
  const handleClose = () => {
    setIsOpen(false)
    setEditData(false)
  };

  return { 
    isOpen, 
    setIsOpen,
    handleOpen,
    handleClose,
    editData,
    setEditData,
  }
}
  
//Изменение количества при клике + или -
export function Counter (cap) {

  const [capacity, setCapacity] = useState(cap.capacity)

  //счетчик членов семьи в плюс
    function handleCountClickPlus() {
      setCapacity(capacity+1) 
    }
  
    //счетчик членов семьи в минус
    function handleCountClickMinus() {
      if (capacity > 1) {
        setCapacity(capacity-1)
      }
    }
 
  return {
    capacity,
    handleCountClickPlus,
    handleCountClickMinus
  }
}