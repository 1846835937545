import React from 'react';
import { SelectionCategory, Cooking } from '../../atoms';
import { useSelector } from 'react-redux'
import { AutoComplete } from '../../../utils/utils.js' 

export function AddIngredient(props) {
  const choiceIngredient= props.choiceIngredient
  const {ingredients} = useSelector(state => state.ingredients)
  const ingredient = ingredients.find(f => f._id === choiceIngredient); 


  return (
    <article className='add-recipe__type_ing'>
      <div className='form__label add-recipe__label_type_ingredients'>
        <input 
          type='search' 
          value={props.value || ''} 
          onChange={props.handleChangeInput} 
          placeholder='Начните вводить ингредиент' 
          className='form__input add-recipe__input_type_ingredients' 
          name={props.name}
          id={props.name}/>
        {props.ingError && <span className="add-form__item-error">{props.ingError}</span> }
        <span className='add-recipe__autocomplete'>
          {props.filtrMassiv.length > 0 && props.hidden &&
            props.filtrMassiv.map((item, index) => (
              <AutoComplete 
                key={index} 
                item={item} 
                onClick={props.handleAutoText} 
                name={props.name}
              />
                
            ))
          }
        </span>
      </div>
      <div className='form__label add-recipe__label_type_weight' htmlFor='weight'>
        <input type='number' step="any" value={props.weight || ''} onChange={props.handleChangeInputWeight} onFocus={props.onFocus} placeholder='Количество' className='add-recipe__input add-recipe__input_type_ingredients' name='weight' id='weight'/>
        {props.weightError && <span className="add-form__item-error weight-error">{props.weightError}</span> }
        <select 
         value={props.measure || ''}
         onChange={props.handleChangeMeasure} 
         onFocus={props.onFocus} 
         className='form__input add-recipe__input_type_measure' 
         name='measure' 
         id='measure'>
          <option value='none' name="none">мера</option>
          {ingredient &&
          ingredient.measure.length !== 0  &&
          ingredient.measure.map((item, index)=> (
            <SelectionCategory item={item} key={item._id}/>
          )) }
        </select>
      </div>
      <Cooking  
        onChange={props.onChange}
    />
 </article>

)
   
}
