import React from 'react';
import './ItemEdit.css';

export function ItemEdit (props) {
  const item = props.item
  
  return (
    <li className='item-edit'>
      <p className='item-edit__name'>{item.nameRu ? item.nameRu : item.title}</p>
      <button className='gg-edit item-edit__button' onClick={()=>props.handleEdit(item, props.name)}/>
      {/*<button className='gg-delete item-edit__button' onClick={()=>props.handleDelete({id: item._id, name: props.name})}/>*/}
    </li>
  )
}