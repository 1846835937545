import React from "react";
const ExpectList = (props) => {
  
  const item = props.item
  const choice = item.ingredients.every(i=> props.ingredientsData.find(c=> c === i))
 const [values, setValues] = React.useState(choice)
 
  React.useEffect(() => {
    setValues(choice)
}, [props.ingredientsData]);

  function handleClick(item) {
    props.onClick(item, values) 
  }
 
  return (
    <li 
      onClick={()=> handleClick(item)} 
      className={`button__type menuweek-button font20Norm ${values && ('button__type_active color')}`}
    >
    {item.title}
    </li>
  )
}
  
export function ExpectItem(props) { 
  return (
    <ul className='form-settings__block_row'>
      {props.types.length > 0 
      ?  props.types.map((item)=> (
        <ExpectList item={item} key={item._id} onClick={props.handleExpectCheck} ingredientsData={props.ingredientsData}/>
 
        ))
      : ''
      }
    </ul>
  );
}