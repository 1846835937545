import React from 'react'; 

export function Cooking(props) {
 
   return (
      <div className='product__cooking'>
      <label className='product-item__label'>
        <input 
          type="radio" 
          value='fresh'  
          onChange={props.onChange}
          className='product-item__choice' 
          name='thermoself' 
          id="fresh"
        /> свежий
        <span className="checkbox-green item__link"></span>
      </label>
      <label className='product-item__label'>
        <input 
          type="radio" 
          value='heat' 
          onChange={props.onChange}
          className='product-item__choice' 
          name='thermoself'
          id="heat"
        /> варка
        <span className="checkbox-green item__link"></span>
      </label>
      <label className='product-item__label'>
        <input 
          type="radio" 
          value='frying'  
          onChange={props.onChange}
          name='thermoself'
          className='product-item__choice' 
          id="frying"
        />жарка
        <span className="checkbox-green item__link"></span>
      </label>
   </div> 

)
   
}
