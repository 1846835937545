import React from 'react';
 
export function MainMeasure (props) {
  const item = props.item
  
  return (
    <select 
    value={props.value || ''} 
    onChange={props.onChange}
    className='add-recipe__input add-recipe__input_type_measure' 
    name='mainMeasure' 
    id='mainMeasure'
  >
    <option value='none' name="none">{props.placeholder}</option>
    <option onChange={props.onChange}>г</option>
    <option onChange={props.onChange}>мл</option>
    <option onChange={props.onChange}>шт</option>
  </select>
  )
}