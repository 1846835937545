import React from 'react';
import { SelectionCategory } from '../../atoms';
 
export function Selection (props) {
   
    return (
    <label className={`form__label form__label_type_${props.name}`} htmlFor={props.name}> 
      <select  
        value={props.value || ''} 
        onChange={props.onChange} 
        className={`form__input form__input_type_${props.name}`}  
        name={props.name} 
        id={props.name}
      >
        <option value="none" name="none">{props.title}</option>
          {props.array && 
            props.array.map((item, index)=> (
            <SelectionCategory item={item} key={index}/>
          ))}
      </select>
    {(props.nameError ==='' || props.nameError === 'none') && <span className="email-error add-form__item-error">Выберите категорию</span>}
    </label>
  )
}

