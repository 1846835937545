import React from 'react';
import './UserActiv.css'; 
import { useSelector } from 'react-redux' 

export function UserActiv(props) {  
  const { userActiv } = useSelector(state => state.users)
  const [dataArray, setDataArray] = React.useState([])

  const filteredData = userActiv.reduce((acc, cur) => {
    if (!acc[cur.userID]) {
      acc[cur.userID] = { 
          userID: cur.userID, 
          login: 0, 
          visit: 0, 
          search: 0, 
          dfoodlist:0, 
          dplan: 0, 
          foodlist: 0, 
          gener: 0, 
          settings: 0, 
          upplan: 0,  
          reset: 0,
          firstTime: new Date(cur.time), 
          lastTime: new Date(cur.time)
        };
    }
    acc[cur.userID][cur.actionType] += 1;
    if (new Date(cur.time) <= new Date(acc[cur.userID].firstTime)) {
        acc[cur.userID].firstTime = new Date(cur.time);
    }
    if (new Date(cur.time) > new Date(acc[cur.userID].lastTime)) {
        acc[cur.userID].lastTime = new Date(cur.time);
    }
    return acc;
  }, {});

  React.useEffect(() => {
    setDataArray(Object.values(filteredData).sort((a,b)=> b.visit - a.visit ))
  }, [userActiv]);

  function handleLogin(data) { 
    setDataArray(Object.values(filteredData).sort((a,b)=> b[data] - a[data] ))
  }

  return (
    <main className='content'>
        <div className='table__thead'>
        <ul className='activ'>
          <li className='activ_id'>userId</li>  
          <li className='activ_item'>ЛОГИН <span onClick={()=>handleLogin('login')} className='gg-sort'></span></li>
          <li className='activ_item'>ВИЗИТ <span onClick={()=>handleLogin('visit')}  className='gg-sort'></span></li> 
          <li className='activ_item'>ПОИСК<span  onClick={()=>handleLogin('search')} className='gg-sort'></span></li>
          <li className='activ_item'>dfoodlist <span  onClick={()=>handleLogin('dfoodlist')} className='gg-sort'></span></li> 
          <li className='activ_item'>dplan <span  onClick={()=>handleLogin('dplan')} className='gg-sort'></span></li> 
          <li className='activ_item'>foodlist <span  onClick={()=>handleLogin('foodlist')} className='gg-sort'></span></li>
          <li className='activ_item'>gener <span  onClick={()=>handleLogin('gener')} className='gg-sort'></span></li>
          <li className='activ_item'>settings <span onClick={()=>handleLogin('settings')}  className='gg-sort'></span></li> 
          <li className='activ_item'>upplan <span  onClick={()=>handleLogin('upplan')} className='gg-sort'></span></li>
          <li className='activ_item'>reset <span  onClick={()=>handleLogin('reset')} className='gg-sort'></span></li>
          <li className='activ_data'>Дата1 <span  onClick={()=>handleLogin('firstTime')} className='gg-sort'></span></li>
          <li className='activ_data'>Дата2 <span  onClick={()=>handleLogin('lastTime')} className='gg-sort'></span></li>

        </ul>
      </div>
      {userActiv.length > 0 &&
        dataArray.map((i, index)=> (
          <ul key={index} className='activ'>
           <li className='activ_id'>{i.userID}</li> 
          <li className='activ_item'> {i.login}</li>
          <li className='activ_item'> {i.visit}</li> 
          <li className='activ_item'>{i.search}</li>
          <li className='activ_item'>{i.dfoodlist}</li> 
          <li className='activ_item'>{i.dplan}</li> 
          <li className='activ_item'>{i.foodlist}</li>
          <li className='activ_item'>{i.gener}</li>
          <li className='activ_item'>{i.settings}</li> 
          <li className='activ_item'>{i.upplan}</li>
          <li className='activ_item'>{i.reset}</li>
          <li className='activ_data'>{`${i.firstTime.getDate()}.${i.firstTime.getMonth() + 1}.${i.firstTime.getFullYear()} ${i.firstTime.getHours()}:${i.firstTime.getMinutes()}`}</li> 
          <li className='activ_data'>{`${i.lastTime.getDate()}.${i.lastTime.getMonth() + 1}.${i.lastTime.getFullYear()} ${i.lastTime.getHours()}:${i.lastTime.getMinutes()}`}</li>
            
          </ul>
      ))}
    </main>
)
   
}
