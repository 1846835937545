import React from 'react';
import { AddButton, ModalContainer, ExceptionCard, ItemEdit } from '../../atoms'
import { OpenModal, filtrSearchArr } from '../../../utils/utils';
import { postLibrary, deleteLibrary, updateLibrary} from '../../../store/api'; 
import { useSelector, useDispatch } from 'react-redux'
import {useFormValidation} from '../../../utils/Validator'

export function ExceptionBlock (props) {
  const {isOpen, handleOpen, handleClose, editData, setEditData } = OpenModal({})
  const dispatch = useDispatch(); 
  const { lib } = useSelector(state => state.lib)
  const {ingredients} = useSelector(state => state.ingredients)
  const { 
    values, 
    setValues,
    resetForm,
    handleChange,
  } = useFormValidation({}); 
  const [filtrMassiv, setFiltrMassiv] = React.useState([]); 
  const [choiceIngredient, setChoiceIngredient] = React.useState([])
  const [ingredient, setIngredient] = React.useState('') 
  const [hidden, sethidden] = React.useState(true)
  const [name, setName] = React.useState(false)

  function handleSubmit(e) {
    e.preventDefault();
    if(!editData) {
      dispatch(postLibrary({name: 'except', values: {...values, ingredients: choiceIngredient}})) 
    } else {
     dispatch(updateLibrary({id: editData._id, name: name, values: {...values, ingredients: choiceIngredient} })) 
    }
    handleClose()
  } 
  React.useEffect(()=> {
    resetForm() 
    if(editData) {
      setValues({...editData})
      setChoiceIngredient(editData.ingredients) 
    }
  }, [isOpen, editData]) 

  function handleDelete(item) { 
   
    setChoiceIngredient((cards)=> cards.filter((c) => c !== item._id));
  }
  function handleEdit(item, name) {
    setName(name)
    setEditData(item)
    handleOpen()
  }

   //Изменение инпута ингредиентов
 function handleChangeInput(e) { 
  setIngredient(e.target.value)
  handleFiltrClick(e.target.value, e.target.name)
  //errorMessage(e)
  sethidden(true)
}

 //Поиск в инпуте по ингридиенту
 function handleFiltrClick(keyword, name) {
  if(keyword!=='') {
  const newMassiv = filtrSearchArr(ingredients, keyword, name)
  setFiltrMassiv(newMassiv)
  } else {
    setFiltrMassiv([])
  }
}

 //Подстановка автоматически значения в инпут из выбранного
 function handleAutoText(text) { 
  setChoiceIngredient([...choiceIngredient, text._id])
  setIngredient('')
  sethidden(false)
}
  return (
    <div className={`data-block data-block_type_${props.title}`} >
      <h2 className='data-block__name'>{props.nameRu}</h2>
      <AddButton 
        onClick={handleOpen}
        type=''
        title='Добавить'
      />
      <ul className='add-recipe__vitamin'>
        {lib !== null && Object.keys(lib).length !== 0 && lib[props.nameEn] !== undefined &&
        lib[props.nameEn].map((item, index)=> (
          <ItemEdit
            key={index}
            item={item}
            name={props.nameEn}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
      </ul>
      <ModalContainer 
        isOpen={isOpen}
        title='vitamins'
        onClose={handleClose}
        
      > 
        <ExceptionCard 
        onChange={handleChange} 
        onSubmit={handleSubmit}
        choiceIngredient={choiceIngredient}
        filtrMassiv={filtrMassiv} 
        handleChangeInput={handleChangeInput}
        handleAutoText={handleAutoText}
        handleDelete={handleDelete}
        ingredient={ingredient}
        hidden={hidden}
        values={values}
        title={!editData ? 'Сохранить': 'Редактировать'}
      />  
      </ModalContainer>
      </div>
  )
}

