import React from 'react'; 
import { InputItem, AddButton } from '../../atoms'

export function DeletePlans(props) {

  return (
      <form onSubmit={props.submit}>
    <InputItem 
      type='date'
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
    />
    <AddButton 
        type='submit'
        title='Удалить старые планы'
     />
     </form>
)
   
}
