//Разметка Добавления витаминов в базу

import React from 'react';
import { AddButton, InputMeasure} from '../'

export function VitaminCard (props) {
  const values= props.values
 
  return (
    <form className="form" onSubmit={props.onSubmit}> 
    {props.arr.map((item, index)=>(
      <InputMeasure 
        item={item}
        type={item.type}
        key={index}
        value={values[item.nameEn] !== undefined ? values[item.nameEn] : ''}
        name={item.nameEn}
        id={item.nameEn}
        nameru={item.nameRu}
        placeholder='введите значение'
        onChange={props.handleChange}
        handleClick={props.handleClick}
      />
    ))}
  
      <AddButton 
        type='submit'
        title={props.title}
      />
    </form>
  )
}
 

