import React from 'react';

export function Page(props) {
 
  return (
    <div className='page-main'>
      <p>Hello</p>
    </div>
  )
   
}