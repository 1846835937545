import React from 'react';
import {IngredientsTable, AddEditIngredient, Search} from '../../organisms'
import { useSelector } from 'react-redux'
import './Ingredients.css';
import { filtrSearchArr, sortCategory, sortName, sortArrDate } from '../../../utils/utils.js'

export function Ingredients(props) {
  const { ingredients } = useSelector(state => state.ingredients)
  const [showModal, setShowModal] = React.useState(false)
  const [showModalEdit, setShowModalEdit] = React.useState(false)
  const [editCard, setEditCard] = React.useState(false)
  const [copyCard, setCopyCard] = React.useState(false)
  const [filtrmassiv, setFiltrMassiv] = React.useState([])
  const [keyword, setKeyword] = React.useState('')
 
  React.useEffect(() => {
    setFiltrMassiv(sortArrDate(ingredients))
  }, [ingredients]);
 
  function handleSortName() {
    setFiltrMassiv(sortName(ingredients))
  }

  function handleSortCategory() {
    setFiltrMassiv(sortCategory(ingredients))
  }

  function handleAddIngredient() {
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
    setShowModalEdit(false)
    setEditCard(false)
    setCopyCard(false)
  }
 
  function handleEdit(card) {
    setShowModalEdit(true)
    setEditCard(card)
    setCopyCard(false)
  }

  function handleCopy(card) {
    setShowModalEdit(true)
    setEditCard(card)
    setCopyCard(true)
  }

    //Слушатель на ввод поисковых слов
    function handleChangeInput(e) {
      setKeyword(e.target.value);
      handleFiltrClick(e.target.value)
    }
  
    //Поиск в инпуте по ингридиенту
    function handleFiltrClick(keyword) {
      if(keyword!=='') {
      const newMassiv = filtrSearchArr(ingredients, keyword)
      setFiltrMassiv(newMassiv)
      } else {
        setFiltrMassiv(ingredients)
      }
    }
 
  return (
    <main className='content'>
      <Search onSearch={handleChangeInput} keyword={keyword}/>
      <button onClick={handleAddIngredient} className='my-recipe__add-button'>Добавить ингредиент</button>
      <table className='table'>
      <caption> Ингридиенты</caption>
      <thead className='table__thead'>
        <tr className='table__tr'>
          <th className='table__th'>Название<span className='gg-sort item__link' onClick={handleSortName}></span></th>
          <th className='table__th'>Категория<span className='gg-sort item__link' onClick={handleSortCategory}></span></th>
          <th className='table__th'>id</th>
          <th className='table__th'>Мера</th>
          <th className='table__th'>Указать цену</th>
          <th className='table__th'>Редактировать</th>
        </tr>
      </thead>
      <tbody>
      {filtrmassiv &&
       filtrmassiv.map((ingredient)=> (
        <IngredientsTable 
          ingredient={ingredient} 
          key={ingredient._id} 
          editIngredient={handleEdit}
          copyIngredient={handleCopy}
          />
       ))
     }
      </tbody>
    </table>
    <AddEditIngredient 
      onClose={handleCloseModal} 
      onError={props.onError} 
      postIng={props.postIng} 
      isOpenEdit={showModalEdit} 
      isOpen={showModal} 
      card={editCard && editCard}
      copyCard={copyCard && copyCard}
    /> 
    </main>
  )
   
}