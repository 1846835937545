//Разметка добавления числовых данных

import React from 'react';
import './ConstData.css';
import { DataBlock, ExceptionBlock } from '../../molecules'
import { DeletePlans } from '../../organisms'
import { AddButton } from '../../atoms'
import { useDispatch, useSelector } from 'react-redux'
import { postLib } from '../../../store/api';
import api from "../../../utils/api";

export function ConstData (props) {
  const dispatch = useDispatch();  
  const { lib, status } = useSelector(state => state.lib)
  const [date, setDate] = React.useState()
  function handleAdd() {
    dispatch(postLib({lib:lib, except: [] }))
  }

  function handleSitemap() {
    api.getSitemap()
    .then(data=> {
      const blob = new Blob([data], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
    })
  }
  function handleAddSubmit(event) {
    event.preventDefault();
    api.deleteOldPlan(date)
    .then(data=> {
      console.log('ok')
    })
  }

  function handleChangeDate(e) {
    setDate(e.target.value)
  }
  return (
    <main className='data'>
      {status === 'resolved' && 
      <AddButton 
        onClick={handleAdd}
        type=''
        title='Добавить библиотеку'
     />}
    
     <DeletePlans 
        submit={handleAddSubmit}
        value={date}
        onChange={handleChangeDate}
        name='delete-plan'
        id='delete-plan'
      />
      
      <DataBlock 
        nameRu='Витамины'
        nameEn= 'vitamins'
      />
      <DataBlock 
        nameRu='Бренды'
        nameEn= 'brands'
      />
      <DataBlock 
        nameRu='Тип питания'
        nameEn= 'typeFoods'
      />
      <DataBlock 
        nameRu='Меры веса'
        nameEn= 'measure'
      />
      <DataBlock 
        nameRu='Блюда'
        nameEn= 'dish'
      />
      <DataBlock 
        nameRu='Категории у ингредиентов'
        nameEn= 'catIn'
      />
      <DataBlock 
        nameRu='Инвентарь'
        nameEn= 'kitchenware'
      />
     {/*} <DataBlock 
        nameRu='Тип ингредиентов'
        nameEn= 'typeIn'
  />*/}
      <ExceptionBlock 
        nameRu='Исключения'
        nameEn= 'except'
      />
      <button className='menuweek-button color' onClick={handleSitemap}>Скачать sitemap</button>
    </main>
  )
}