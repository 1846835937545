import React from 'react';
import {TypesFood} from '../../atoms/'
 
export function ChoiceDish (props) {
  const meals = props.meals
  const dish =meals.dish
  const updateDish = [...dish]
 
  function handleChange(item) {
    updateDish.forEach(i=> {
     if(item.nameEn === i.nameEn ) {
        return i.boolean = item.boolean
     }
   })
    props.handleCheckBox(updateDish)
  }

  return (
    <div className='form-settings__degree'>
      <p className='form-settings__meals font24Bold'>{`Выберите блюда для ${props.meals.nameRu}а`}</p>
      {dish.length > 0 &&
        <TypesFood handleCheckBox={handleChange} types={dish}  />
      }
    </div>  
  )
}
