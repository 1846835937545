import React from 'react';
import './Footer.css';

export function Footer() {

  return (
    <footer className='footer'>
    
    </footer>
)
   
}
