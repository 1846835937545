import React from 'react';
import './Header.css';
import { CurrentUserContext } from "../../../contexts/CurrentUserContext.js";


export function Header(props) {
  const currentUser = React.useContext(CurrentUserContext);
  return (
    <header className='header '>
      {props.loggedIn &&
      <>
      <p>Привет,  {currentUser.name}</p>
      <button onClick={props.onClick} className="header my-recipe__add-button">Выйти</button>
      </>
    }
    </header>
  )   
}
