//Инпуты с возможностью множественного выбора

import React from 'react'; 
import './range.css'; 

export function Range (props) { 
 
  return (
    <label className='range__label'htmlFor='cal'> 
      <input className='range__input' value={props.calories || ''} type="range" min="1200" max="3000" onChange={props.onChange} step="50"  id='calories' name='calories'/>
      <span className='range__value font20Norm'>{props.calories} Ккал</span>
    </label>
  )
}

