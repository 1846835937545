import React from 'react';
import './StepText.css';

export function StepText (props) {
  const desc = props.desc
  
  const lengthDesc = desc[props.name] ? desc[props.name].length : 0
  let step
  const stepDesc = Object.entries(desc).map((item)=> {
    if(item[0] === props.name) {
      step = item[1]
    }
    return step
  }) 
  
  return (
    <div className="text-area__block">
      <p className="text-area_title">Шаг {props.item}</p>
      <p className="text-area__count">{lengthDesc}/5000</p>
      {props.step > 2 &&
        <p className='gg-close text-area__close' onClick={props.onClick}></p>
      }
      <textarea 
        value={props.value || step || ''} 
        onChange={props.onChange} 
        type="textarea" 
        cols='50' 
        min="0" 
        max="5000" 
        placeholder='Инструкция по приготовлению ' 
        autoFocus 
        className="text-area" 
        name={props.name}
        id={props.name} 
      />  
    </div>
  )
}