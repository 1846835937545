import React from 'react'; 

export function InputArea (props) {
  const desc = props.desc
  
   
  return (
    <label className="text-area__block">
      <textarea 
        value={props.value || ''} 
        onChange={props.onChange} 
        type="textarea" 
        cols='50' 
        min="0" 
        max="500" 
        placeholder={props.placeholder}
        autoFocus 
        className="text-area__input" 
        name={props.name}
        id={props.name} 
      />  
    </label>
  )
}