import React from 'react';
import './Main.css';
import { Page } from '../../organisms';

export function Main(props) {
 
  return (
    <main className='content'>
      <Page />
    </main>
)
   
}
