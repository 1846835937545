import { createSlice } from '@reduxjs/toolkit';

const IngredientsSlise = createSlice({
    name: 'todos',
    initialState: {
        todos: [],
      status: null,
      error: null,
    },
    reducers: {
      addTodo(state, action) {
        state.todos.push({
          id: action.payload._id,
          ingredient: action.payload.ingredient,
          measure: action.payload.measure,
          weight: action.payload.weight,
        });
        },
      removeTodo(state, action) {
        state.todos = state.todos.filter(todo => todo.id !== action.payload.id);
      }
    },  
    
  })

  export const {addTodo, removeTodo} = IngredientsSlise.actions;

export default IngredientsSlise.reducer;

