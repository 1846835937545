import React from 'react';
 
export function ItemList (props) {
  const item = props.item
  
  return (
    <div className='item-edit'>
      <p className='item-edit__name'>{item.nameRu}</p>
     <button type='button' className='gg-delete item-edit__button' onClick={()=>props.handleDelete(item)}/>
    </div>
  )
}