import React from 'react';
import './RecipesTable.css';
import { deleteRecipe, editRecipeMeta } from '../../../store/api.js'
import {useDispatch, useSelector} from 'react-redux'
import { InputItem } from '../../atoms';
import {useFormValidation} from '../../../utils/Validator' 

const MeasureData = (props) => {
  const {ingredients} = useSelector(state => state.ingredients)
  const ingredient = ingredients.find(f => f._id === props.item._id || f.name === props.item.name);
  return (
    <div className='recipe__ingredients'>
    <p className='ingredient__measure_text'>{ingredient.name} {ingredient.trait}:</p>
    <p className='ingredient__measure_text text-rigth'>{props.item.weight}</p>
    <p className='ingredient__measure_text'>{props.item.choisMeasure}</p>
    </div>
  )
}
export function RecipesTable(props) {
  const recipe = props.recipe
  const dispatch = useDispatch()
  let id = recipe._id;
  const [recipeActive, setRecipeActive] = React.useState([])
  const owner = props.users.find(i=> recipe.owner === i._id) 
  let acceptRecipe = props.users.some(i=> i.bonus.recipeActive.find(k=> k === id ))
  const { 
    values, 
    handleChange,
    setValues,
    } = useFormValidation({});

    React.useEffect(() => {
        setValues({
          name: recipe.name,
          category: recipe.category,
          time: recipe.time,  
          activeCooking: recipe.activeCooking,
          preparation: recipe.preparation,
          advice: recipe.advice, 
          fresh: recipe.fresh,
          heat: recipe.frying,
          activeCooking: recipe.activeCooking,
          advice: recipe.advice,
          calories: recipe.calories,
          comments: recipe.comments,
          desc: recipe.desc,
          description: recipe.description,
          image: recipe.image,
          ingredients: recipe.ingredients,
          kitchenware: recipe.kitchenware, 
          link: recipe.link,
          metaDesc: recipe.metaDesc ? recipe.metaDesc : '',
          minPortion: recipe.minPortion,
          nameRu: recipe.nameRu,
          servings: recipe.servings,
          typeOfDiet: recipe.typeOfDiet,
          id: recipe._id,
        })
    }, []); 

  console.log(values)
  React.useEffect(()=>{
    if(owner !== undefined) {
      setRecipeActive([id, ...owner.bonus.recipeActive]);
    }
  }, [props.users])

  function handleDelete() {
    dispatch(deleteRecipe(id))
  }

   function handleEdit() {
    props.onEditRecipe(recipe)
   }

   function handleAccept() {
     if(owner !== undefined) {
      const bonus = {
        summ: owner.bonus.summ +10,
        recipeActive: recipeActive,
        sumTraffick: owner.bonus.sumTraffick,
        recipeInActive: owner.bonus.recipeInActive,
      };
      props.onAcceptRecipe(owner._id, bonus)
     }
    
   }
   
   /*function handleNoAccept() {
    if(owner !== undefined) {
      const bonus = {
        summ: owner.bonus.summ +10,
        recipeActive: recipeActive,
        sumTraffick: owner.bonus.sumTraffick,
        recipeInActive: owner.bonus.recipeInActive,
      };
      props.onNoAcceptRecipe(owner._id, bonus)
     }
   }*/

   function handleSave(event) {
    event.preventDefault();  
    dispatch(editRecipeMeta(values))
  }

  return (
    <tr className='table__tr_recipe item__link' >
      <td className='table__td_recipe'>{recipe.name}</td>
      <td className='table__td_recipe'><img className='table__td_img' alt={recipe.name} src={recipe.image[0]}/></td>
      <td className='table__td_recipe'>{recipe.category}</td>
  
      <td className='table__td_recipe'>{recipe.ingredients &&
      recipe.ingredients.map((item, index)=> (
        <MeasureData item={item} key={index}/>
        
      ))}</td>
      <td className='table__td_recipe'>{recipe.time}</td>
      <td className='table__td_recipe'>{recipe.servings}</td>
      <td className='table__td_recipe'>
        <InputItem
            type='textarea' 
            value={values.metaDesc || ''} 
            onChange={handleChange} 
            placeholder='МетаОписание' 
            name='metaDesc' 
            id='metaDesc' 
          />
          </td>
      <td className=' table__td_buttons'>
      <button className='gg-save item__link' onClick={handleSave}></button>
        <button className='gg-delete' onClick={handleDelete}></button>
        <button className='gg-edit' onClick={handleEdit}></button>
        { acceptRecipe
        ? <button className='gg-accept'></button>
        : <button className=' ' onClick={handleAccept}>Принять</button>
          //<button className='gg-no-accept' onClick={handleNoAccept}></button>
        }
        
        
        </td>

    </tr>
  )
   
}