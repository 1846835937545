import homeicon from '../images/home.jpg'
import custom from '../images/custom.png'
import signup from '../images/signup.png'

export const profileCategory =[
  {
    id: '1pc',
    icon: homeicon,
    category: 'Мои данные',
    path: 'my-info',
  },
  {
    id: '2pc',
    icon: custom,
    category: 'Настройки меню',
    path: 'settings',
  },
  {
    id: '3pc',
    icon: custom,
    category: 'Мои рецепты',
    path: 'my-recipes',
  },
  {
    id: '4pc',
    icon: custom,
    category: 'Мои меню',
    path: 'my-menu',
  },
  /*{
    id: '5pc',
    icon: custom,
    category: 'Список продуктов',
    path: 'food-list',
  },*/
  {
    id: '6pc',
    icon: signup,
    category: 'Выход',
    path: 'signout'
  },
]
/*
export const categoryFood = [
  'Завтрак',
  'Супы',
  'Основное блюдо',
  'Десерты и Выпечка',
  'Закуски',
  'Салаты',
  'Перекусы',
  'Напитки'
]
*/
export const subscriptions = [
  {
    title: 'Стандарт',
    subs: 'standart',
    text: 'Подходит для тех чья семья питается по единому графику питания',
    price: 99,
    id: '99mbp',
    stocks: '60 дней бесплатно, без тестовых платежей',
    active: true,
  },
  {
    title: 'Семейный',
    subs: 'family',
    text: 'Возможномть создания меню для членов семьи, если не все питаются по одному меню. Например детское меню + традиционное + диета. до 4-х типов' ,
    price: 199,
    id: '199mbp',
    stocks: '60 дней бесплатно, без тестовых платежей',
    active: false,
  },
  {
    title: 'Премиум',
    subs: 'premium',
    text: 'Возможность создания неограниченного количества меню для разных типов питания.Заинтерисует диетологов, нутрициологов, которые разрабатывают план питания ежедневно для своих клиентов',
    price: 999,
    id: '999mbp',
    stocks: '60 дней бесплатно, без тестовых платежей',
    active: false,
  },
]
export const mealsArray = [
  {
    nameRu: 'Завтрак',
    nameEn: 'breakfast',
    boolean: true,
    dish: [
      {
        nameRu: 'Завтрак',
        nameEn: 'breakfasts',
        boolean: true
      }
    ]
  },
  {
    nameRu: '2-й завтрак',
    nameEn: 'lunch',
    boolean: false,
    dish: [
      {
        nameRu: 'Перекусы',
        nameEn: 'snacks',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Обед',
    nameEn: 'dinner',
    boolean: true,
    dish: [
      {
        nameRu: 'Супы',
        nameEn: 'soup',
        boolean: true
      },
      {
        nameRu: 'Основное блюдо',
        nameEn: 'dinners',
        boolean: true
      },
      {
        nameRu: 'Десерты и Выпечка',
        nameEn: 'dеsert',
        boolean: false
      },
      {
        nameRu: 'Салаты',
        nameEn: 'salads',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Полдник',
    nameEn: 'snack',
    boolean: false,
    dish: [
      {
        nameRu: 'Перекусы',
        nameEn: 'snacks',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Ужин',
    nameEn: 'supper',
    boolean: true,
    dish: [
      {
        nameRu: 'Супы',
        nameEn: 'soup',
        boolean: true
      },
      {
        nameRu: 'Основное блюдо',
        nameEn: 'dinners',
        boolean: true
      },
      {
        nameRu: 'Десерты и Выпечка',
        nameEn: 'dеsert',
        boolean: false
      },
      {
        nameRu: 'Салаты',
        nameEn: 'salads',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Поздний ужин',
    nameEn: 'lateDinner',
    boolean: false,
    dish: [
      {
        nameRu: 'Перекусы',
        nameEn: 'snacks',
        boolean: true
      }
    ]
  },
]

export const weekdayArray = [
  {
    nameRu: 'Каждый день',
    nameEn: 'everyday',
    boolean: true
  },
  {
    nameRu: 'Пн',
    nameEn: 'monday',
    boolean: true
  },
  {
    nameRu: 'Вт',
    nameEn: 'tuesday',
    boolean: true
  },
  {
    nameRu: 'Ср',
    nameEn: 'wednesday',
    boolean: true
  },
  {
    nameRu: 'Чт',
    nameEn: 'thursday',
    boolean: true
  },
  {
    nameRu: 'Пт',
    nameEn: 'friday',
    boolean: true
  },
  {
    nameRu: 'Сб',
    nameEn: 'saturday',
    boolean: true
  },
  {
    nameRu: 'Вс',
    nameEn: 'sunday',
    boolean: true
  },
]


export const text = {
aboutInfo1: 'С давних времен приготовление пищи является основопологающим для нормального функционирования человеческого организма.Сегодня приготовление пищи это квест, который проходит каждая хозяйка. Как, а главное чем накормить своих домочадцев, когда один не ест одно, а второй- другое. И времени в нашем ультрадинамичном мире все меньше на себя.',
aboutInfo2: 'Данная программа облегчит вам жизнь не только выбрав и рассчитав время на приготовление еды на каждый день, но и поможет выбрать список и количество необходимых вам продуктов в зависимости от количества в семье человек и подобранного вам меню на неделю. При этом наша программа экономит ваши деньги и конечно же ваше время. При походе в магазин или заказе через интернет, вы не всегда уверены все ли вы включили в список. Или возможно купили больше чем был рассчитан ваш бюджет.',
aboutInfo3: 'Вкусно, полезно и легко - девиз нашей программы. Вы можете подобрать калораж который вам необходим, можете рассчитать праздничный стол в зависимости от количества присутствующих и сравнить затраты с походом в кафе.',
} 

export const pattern = {
  email: '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  name: '^[a-zA-Zа-яА-ЯЁё\\s\\-]+$',
  phone: '^(\\+7||8)[\\s(]*\\d{3}[)\\s]*\\d{3}[\\s-]?\\d{2}[\\s-]?\\d{2}',
  floor: '^[0-9]{1,2}\\/[0-9]{1,2}'
}

export const errorMessage = {
  emailandPasswordError: 'Ошибка авторизации. Неверный email или пароль.',
  tokenError: 'При авторизации произошла ошибка. Токен не передан или передан не в том формате.',
  forbiddenErr: 'У вас нет доступа к администрации сайта',
  forbiddenErrEdit: 'У вас нет доступа к изменению данных поьзователей',
  registrationError: 'Пользователь с таким email уже существует.',
  searchError: 'Во время запроса произошла ошибка. Подождите немного и попробуйте ещё раз».',
  keywordNull: "Нужно ввести ключевое слово",
  badRequestErr: 'При обновлении профиля произошла ошибка.',
  internalServerErr: 'На сервере произошла ошибка.',
  emailError: 'Пользователь с таким email уже существует',
  registrError: 'При регистрации пользователя произошла ошибка.',
  conflictErr: 'Слишком большое количество запросов.Попробуйте чуть позже',
  logoutErr: 'Не удалось выйти из системы',
  imageNoFile: 'Неверный формат фотографии, попробуйте еще раз'
}


export const succesOk = {
  changeInfoUser: 'Данные успешно обновлены!',
  signinOk: 'Вы успешно зарегистрированы',
  subNo: 'Закончен срок действия бесплатной подписки',
  subOk: 'Подписка продлена',
  menuSave: 'Меню успешно сохранено'
}

export const infoMessage = {
  dontFindMovie: 'Ничего не найдено!',
  blockedSaveMenu: 'Невозможно сгенерировать меню на прошедшие даты, выберите ближайшие даты!',
  noDateSelected: 'Необходимо выбрать даты для формирования меню!',
  savedSetting: 'Настройки обновлены!',
  errorCheckProduct: 'Этот ингредиент уже есть в списке продуктов, нажмите кнопку редактировать, если нужно изменить его количество!',
  badRequestErr: 'Проверьте поля. Невозможно сохранить данные'
}

export const authErrors = {
  badRequestErr: 400,
  unauthorizedErr: 401,
  forbiddenErr: 403,
  notFoundErr: 404,
  conflictErr: 409,
  internalServerErr: 500,
  mongoErr: 11000,
}

/*

export const settingMeals=[
  {
    nameRu: 'Супы',
    nameEn: 'soup',
    boolean: false
  },
  {
    nameRu: 'Основное блюдо',
    nameEn: 'dinners',
    boolean: false
  },
  {
    nameRu: 'Десерты и Выпечка',
    nameEn: 'dеsert',
    boolean: false
  },
  {
    nameRu: 'Салаты',
    nameEn: 'salads',
    boolean: false
  },
]*/
/*
export const categoryIng = [
  'Вода и напитки',
  'Зелень',
  'Злаки и зерновые',
  'Консервы',
  'Крупы',
  'Макаронные изделия',
  'Масла растительные',
  'Мясо и мясопродукты',
  'Молочные продукты',
  'Овощи',
  'Овощи-корнеплоды',
  'Овощи ферментированные',
  'Орехи и Сухофрукты',
  'Полуфабрикаты',
  'Рыба',
  'Сахар и кондитерские изделия',
  'Салаты',
  'Семена',
  'Соусы',
  'Специи',
  'Сыры',
  'Фрукты',
  'Хлебобулочные изделия',
  'Ягоды',
  'Яйца',
]*/
/*
export const allMeasure = [
  {
    nameEn: 'gramm',
    nameRu: 'г',
    ratio: 0
  },
  {
    nameEn: 'kg',
    nameRu: 'кг',
    ratio: 0
  },
  {
    nameEn: 'pc',
    nameRu: 'шт',
    ratio: 0
  },
  {
    nameEn: 'ml',
    nameRu: 'мл',
    ratio: 0
  },
  {
    nameEn: 'litre',
    nameRu: 'литр',
    ratio: 0
  },
  {
    nameEn: 'beam',
    nameRu: 'пучок',
    ratio: 0
  },
  {
    nameEn: 'stem',
    nameRu: 'стебель',
    ratio: 0
  },
  {
    nameEn: 'clove',
    nameRu: 'зубчик',
    ratio: 0
  },
  {
    nameEn: 'head',
    nameRu: 'головка',
    ratio: 0
  },
  {
    nameEn: 'pinch',
    nameRu: 'щепотка',
    ratio: 0
  },
  {
    nameEn: 'glass',
    nameRu: 'стакан',
    ratio: 0
  },
  {
    nameEn: 'teaspoon',
    nameRu: 'ч.л',
    ratio: 0
  },
  {
    nameEn: 'tablespoon',
    nameRu: 'ст.л',
    ratio: 0
  },
  {
    nameEn: 'taste',
    nameRu: 'по вкусу',
    ratio: 0
  },
]

export const typeFood = [
  {
    nameEn: 'traditionalFood',
    nameRu: 'Традиционное',
    boolean: true,
  },
  {
    nameEn: 'vegan',
    nameRu: 'Веган',
    boolean: false,
  },
  {
    nameEn: 'lactoOvoVegan',
    nameRu: 'Вегетарианское',
    boolean: false,
  },
  {
    nameEn: 'lactoVegan',
    nameRu: 'Цельное питание',
    boolean: false,
  },
  {
    nameEn: 'properNutrition',
    nameRu: 'ПП',
    boolean: false,
  },
  
  {
    nameEn: 'rawFood',
    nameRu: 'Сыроедение',
    boolean: false,
  },
  {
    nameEn: 'kidsmenu',
    nameRu: 'Детское меню',
    boolean: false,
  },
  {
    nameEn: 'glutenFree',
    nameRu: 'Без глютена',
    boolean: false,
  },
  {
    nameEn: 'paleoDiet',
    nameRu: 'Палео',
    boolean: false,
  },
  {
    nameEn: 'ketoDiet',
    nameRu: 'Кето',
    boolean: false,
  },
  {
    nameEn: 'gapsDiet',
    nameRu: 'Гапс',
    boolean: false,
  },
  {
    nameEn: 'detox',
    nameRu: 'Детокс',
    boolean: false,
  },
]
/*
export const typeFoods = [
  {
    nameEn: 'traditionalFood',
    nameRu: 'Традиционное',
    boolean: true,
  },
  {
    nameEn: 'vegan',
    nameRu: 'Веган',
    boolean: false,
  },
  {
    nameEn: 'lactoOvoVegan',
    nameRu: 'ЛактоОвоВеган',
    boolean: false,
  },
  {
    nameEn: 'lactoVegan',
    nameRu: 'ЛактоВеган',
    boolean: false,
  },
  {
    nameEn: 'properNutrition',
    nameRu: 'ПП',
    boolean: false,
  },
  
  {
    nameEn: 'rawFood',
    nameRu: 'Сыроедение',
    boolean: false,
  },
  {
    nameEn: 'kidsmenu',
    nameRu: 'Детское меню',
    boolean: false,
  },
  {
    nameEn: 'glutenFree',
    nameRu: 'Без глютена',
    boolean: false,
  },
  {
    nameEn: 'paleoDiet',
    nameRu: 'Палео',
    boolean: false,
  },
  {
    nameEn: 'ketoDiet',
    nameRu: 'Кето',
    boolean: false,
  },
  {
    nameEn: 'gapsDiet',
    nameRu: 'Гапс',
    boolean: false,
  },
  {
    nameEn: 'detox',
    nameRu: 'Детокс',
    boolean: false,
  },

]*/

export const measures = {
  vegetables: ['г', 'шт', 'кг'],
  cereals: ['г', 'кг', 'столовая ложка', 'стакан', ],
  litleVegetables: ['г', 'шт', 'кг', 'зубчик', 'головка'],
  main: ['г','кг'],
  verdure: ['г', 'шт', 'кг', 'пучок', 'стебель'],
  pasta: ['г', 'кг', 'столовая ложка', 'стакан', 'чайная ложка'],
  broth: ['мл', 'литр', 'столовая ложка', 'стакан', 'чайная ложка'],
}

export const benefit = [
  {
    id: '1ben',
    title: 'Быстро и просто',
    text: 'Создавайте меню одним кликом, на любое количество дней одновременно, с возможностью его корректировать и сохранять историю меню',
  },
  {
    id: '2ben',
    title: 'Настрой под себя',
    text: 'Гибкие настройки меню, с учетом графика питания, типа питания или диеты, количества людей в семье, максимального времени готовки',
  },
  {
    id: '3ben',
    title: 'Список Продуктов',
    text: 'Получите список продуктов на любое количество дней из плана, с возможностью добавить доп продукты или отметить те что в наличии',
  },
  {
    id: '4ben',
    title: 'Рецепты для популярных типов питания',
    text: '',
  },
  {
    id: '5ben',
    title: 'График питания от 1 до 6 раз в день',
    text: mealsArray,
  },
  {
    id: '6ben',
    title: 'Праздничное меню',
    text: 'Возможность составить праздничное меню с учетом приглашенных гостей, количеством блюд в каждой категории',
  },
]

export const vitamins =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
    type: 'text',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
    type: 'text',
  },
  {
    nameRu: 'Альтернативное название',
    nameEn: 'nameAlt',
    type: 'text',
  },
  {
    nameRu: 'Описание',
    nameEn: 'desc',
    type: 'textarea',
  },
  {
    nameRu: 'Норма взрослая, мг/сут',
    nameEn: 'standard',
    type: 'number',
  },
  {
    nameRu: 'Верхний допустимый уровень, мг/сут',
    nameEn: 'upperLevel',
    type: 'number',
  },
  {
    nameRu: 'Норма детская минимум, мг/сут',
    nameEn: 'standardChildMin',
    type: 'number',
  },
  {
    nameRu: 'Норма детская максимум, мг/сут',
    nameEn: 'standardChildMax',
    type: 'number',
  },
  {
    nameRu: 'Мера',
    nameEn: 'measure',
    type: 'text',
  },

]


export const brands =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
    type: 'text',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
    type: 'text',
  },
  {
    nameRu: 'Описание',
    nameEn: 'desc',
    type: 'textarea',
  },
  {
    nameRu: 'Фасовка',
    nameEn: 'packing',
    type: 'number',
  },
  {
    nameRu: 'Фото',
    nameEn: 'image',
    type: 'file',
  },
]

export const kitchenware =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
    type: 'text',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
    type: 'text',
  },
  {
    nameRu: 'Описание',
    nameEn: 'desc',
    type: 'textarea',
  },
  
]

export const typeFoods =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
    type: 'text',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
    type: 'text',
  },
  {
    nameRu: 'Значение',
    nameEn: 'boolean',
    type: 'text',
  },
  {
    nameRu: 'Описание',
    nameEn: 'desc',
    type: 'textarea',
  },
]
export const typeIn =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
    type: 'text',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
    type: 'text',
  },
  {
    nameRu: 'Значение',
    nameEn: 'boolean',
    type: 'text',
  },
  {
    nameRu: 'Описание',
    nameEn: 'desc',
    type: 'textarea',
  },
]
export const measure =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
    type: 'text',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
    type: 'text',
  },
  {
    nameRu: 'Соотношение',
    nameEn: 'ratio',
    type: 'number',
  },
]


export const catIn =  [
  {
    nameRu: 'Русское название',
    nameEn: 'nameRu',
  },
  {
    nameRu: 'Английское название',
    nameEn: 'nameEn',
  },
]
 