import React from 'react';
import  { InputItem } from '../../atoms'

export function PriceBlock(props) {
 
  return (
    <>
          <InputItem
            type='number' 
            value={props.price || ''} 
            onChange={props.onChange} 
            placeholder='Укажите цену' 
            name='price' 
            id='price'
            disabled={props.disabled}
          />
          <InputItem
            type='number' 
            value={props.weightprice || ''} 
            onChange={props.onChange} 
            placeholder='Количество'  
            name='weightprice' 
            id='weightprice'
          />
          <p className='add-recipe__input add-recipe__input_type_measure'>{props.mainMeasure}</p>

          {/*<select 
            value={props.measureprice || ''} 
            onChange={props.onChange}
            className='add-recipe__input add-recipe__input_type_measure' 
            name='measureprice' 
            id='measureprice'
          >
            <option value="none" name="none">мера</option>
                  {props.lib.measure !== undefined && props.lib.measure.length > 0 &&
                props.lib.measure.map((item, index)=> (
                    <SelectionCategory 
                    item={item} 
                    key={index}/>
                  ))}
            
                </select>*/}
       
   </>
  )        
}
  