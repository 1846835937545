import React from 'react';
import {TypesFood} from '../../atoms/'
import {ChoiceDish} from './ChoiceDish' 

export function ChoiceMeals (props) {
  const types=props.types
  const arr = types.filter(i=> (i.nameEn ==='supper' && i.boolean) || (i.nameEn === 'dinner' && i.boolean))

  return (
    <>
    <div className='form-settings__block_row'>
      <h3 className='form-settings__meals font24Bold'>Выберите приемы пищи</h3>
      {types.length > 0 &&
        <TypesFood handleCheckBox={props.handleCheckBox} types={types}/>
     
      }
      </div>
       <div className='form-settings__block_column'>
      {arr.length > 0 &&
        arr.map((c)=> (
          <ChoiceDish meals={c} key={c.nameEn} handleCheckBox={props.handleCheckBox} />   
       )) 
      }
    </div>
    </>
  )
}
