//Разметка кнопки

import React from 'react';

export function AddButton (props) {

  return (
    <button 
      type={props.type}
      disabled={props.submitDisabled}
      onClick={props.onClick} 
      className={`menuweek-button color add-button__type_${props.type} ${props.submitDisabled ? ('add-button__disabled') : ''}`}>
      {props.title}
    </button>
  )
}


