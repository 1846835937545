import React from 'react';
import './Recipes.css';
import {AddButton} from '../../atoms';
import {RecipesTable, AddEditRecipe, Preloader} from '../../organisms';
import { useSelector, useDispatch } from 'react-redux'; 
import { fetchFilter } from '../../../store/api.js' 

export function Recipes(props) {
  const {recipePage, totalCount, fetching} = useSelector(state => state.recipes)
  const dispatch = useDispatch()
  const [showModal, setShowModal] = React.useState(false)
 // const [fetching, setFetching] = React.useState(false)
  const [showModalEdit, setShowModalEdit] = React.useState(false) 
  const [editCard, setEditCard] = React.useState(false)
  const limit = 12 
  //const[totalCount, setTotalCount] = React.useState(0)
  const allPages = Math.ceil(totalCount/limit)
  const pages = Array.from({length: allPages}, (_, i) => i + 1)
  const [currentPage, setCurrentPage] = React.useState(1) 
  const users = props.users
  const parametrSearch = {
    currentPage: currentPage,
    limit: limit,
  } 
  
  React.useEffect(()=> { 
    if(currentPage) {  
      dispatch(fetchFilter(parametrSearch))
    } 
  },[currentPage])

  function handleAddIngredient() {
    setShowModal(true)
  } 
   
  function handleCloseModal() {
    setShowModal(false)
    setShowModalEdit(false)
    setEditCard(false)
  }

  function handleEdit(card) {
    setShowModalEdit(true)
    setEditCard(card)
  }

 //Счетчик изменения страниц
 function handleCounter(page) { 
  setCurrentPage(page)
}
 
  return (
    <main className='content'>
      <AddButton 
      onClick={handleAddIngredient}
      title='Добавить рецепт'
      type='recipe'
      />
      <p>Число рецептов: {totalCount}</p>
      <table className='table'>
      <caption> Рецепты</caption>
      <thead className='table__thead'>
        <tr className='table__tr_recipe'>
          <th className='table__th'>Название<span className='gg-sort item__link' ></span></th>
          <th className='table__th'>Фото</th>
          <th className='table__th'>Категория<span className='gg-sort item__link'></span></th>
          <th className='table__th'>Ингридиенты</th>
          <th className='table__th'>Время готовки</th>
          <th className='table__th'>Кол-во порций</th>
          <th className='table__th'>Метаописание</th>
          <th className='table__th'>Редактировать</th>
        </tr>
      </thead>
      <tbody>
        {fetching === 'resolved' ?
        recipePage.map((item)=> (
          <RecipesTable users={users} recipe={item} key={item._id}  onAcceptRecipe={props.onAcceptRecipe} onNoAcceptRecipe={props.onNoAcceptRecipe} onEditRecipe={handleEdit}/>
        ))
        : <Preloader/>
        }
         <div className='pages'>
            {pages.map((page, index)=> 
              <span 
              className={`page__number ${currentPage === page ? 'page__current' : ''}`}
              key={index} 
              onClick={()=>handleCounter(page)}>{page}</span>
            )}
        </div>
      </tbody>
    </table>
    <AddEditRecipe onClose={handleCloseModal} postRecipe={handleCloseModal} card={editCard && editCard} isEditOpen={showModalEdit} isOpen={showModal}  />
    </main>
)
   
}
