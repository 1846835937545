//Разметка Добавления групп исключений

import React from 'react';
import { ItemList, AddButton, InputMeasure} from '../'
import { AutoComplete } from '../../../utils/utils.js'
import { useSelector, useDispatch } from 'react-redux'

export function ExceptionCard (props) {
  const values= props.values
  const choiceIngredient= props.choiceIngredient
  const {ingredients} = useSelector(state => state.ingredients)
  const nameIngredient = choiceIngredient.map(f => ingredients.find(c=> c._id === f)); 

  return (
    <form className="form" onSubmit={props.onSubmit}>  
      <InputMeasure 
        type= 'text'
        value={values.title}
        name='title'
        id='title'
        nameru='Русское название'
        placeholder='введите значение'
        onChange={props.onChange}
      />
      
      <div>
      {nameIngredient && 
          nameIngredient.map((item, index)=>(
            <ItemList 
            handleDelete={props.handleDelete}
            item={item}
            key={index}
            />  
          ))}
      <div className='form__label add-recipe__label_type_ingredients'>
        <input 
          type='search' 
          value={props.ingredient || ''} 
          onChange={props.handleChangeInput} 
          placeholder='Начните вводить ингредиент' 
          className='form__input add-recipe__input_type_ingredients' 
          name='ingredient'
          id= 'ingredient'
          />
        {props.ingError && <span className="add-form__item-error">{props.ingError}</span> }
        <span className='add-recipe__autocomplete'>
          {props.filtrMassiv.length > 0 && props.hidden &&
            props.filtrMassiv.map((item, index) => (
              <AutoComplete 
                key={index} 
                item={item} 
                onClick={props.handleAutoText} 
                name={props.name}
              />
                
            ))
          }
        </span>
      </div>
      </div>
    
      <AddButton 
        type='submit'
        title={props.title}
      />
    </form>
  )
}

