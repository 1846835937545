import React from 'react';
import './IngredientsTable.css';
import { deleteIngredients, editIngredients } from '../../../store/api.js'
import {useDispatch} from 'react-redux'
import { PriceBlock } from '../../molecules';
import {useFormValidation} from '../../../utils/Validator' 

const MeasureData = (props) => {
  return (
    <div className='ingredient__measure'>
    <p className='ingredient__measure_text'>{props.i.nameRu}</p>
    <p className='ingredient__measure_text'>{props.i.ratio}</p>
  </div>
  )
}
export function IngredientsTable(props) {
  const { 
    values, 
    handleChange,
    setValues,
    } = useFormValidation({});
  const ingredient = props.ingredient
  const dispatch = useDispatch()
  let id = ingredient._id
  const [measure, setMeasure] = React.useState()
  
  React.useEffect(() => {
    setMeasure(ingredient.measure)
    setValues({
      name: ingredient.name,
      price: ingredient.price && ingredient.price.rate,
      measureprice: ingredient.price && ingredient.price.measureprice,
      weightprice: ingredient.price && ingredient.price.weightprice,
      category: ingredient.category,
      trait: ingredient.trait,
      frying: ingredient.frying,
      calories: ingredient.calories.calories,
      protein: ingredient.calories.protein,
      fats: ingredient.calories.fats,
      carb: ingredient.calories.carb,
      digest: ingredient.calories.digest,
      desc: ingredient.desc,
      author: ingredient.link,
      id: ingredient._id,
      cooking: ingredient.cooking,
      purified: ingredient.purified,
      packing: ingredient.packing ,
      mainMeasure: ingredient.mainMeasure,
      vitamins: ingredient.vitamins,
      brands: ingredient.brands,
      season: ingredient.season,
      nameRu: ingredient.nameRu ,
      nameEn: ingredient.nameEn,
    }) 
      
   
  }, []);
 
  function handleDelete() {
    dispatch(deleteIngredients(id))
   }

   function handleEdit() { 
    props.editIngredient(ingredient)
   }
 
   function handleSave(event) {
    event.preventDefault(); 
    dispatch(editIngredients({values, measures: measure, vitamins: values.vitamins, choiceIngredient: values.brands, id: ingredient._id }))
  }
 
  function handleCopy(event) {
    event.preventDefault(); 
    props.copyIngredient(ingredient)
  }
  return (
    <tr className={`table__tr item__link ${ingredient.nameEn && 'table__ok'}`} >
      <td className='table__td'>{ingredient.name} {ingredient.trait}</td>
      <td className='table__td'>{ingredient.category}</td>
      <td className='table__td'>{ingredient._id}</td>
      <td className='table__td'>{ingredient.measure &&
      ingredient.measure.map((i, index)=> (
        <MeasureData i={i} key={index}/>
      ))}</td>
     <td className='table__td_price'> 
        <PriceBlock 
          price={values.price} 
          onChange={handleChange} 
          weightprice={values.weightprice}
          mainMeasure={values.measureprice}
          disabled ={true}
        />
      </td> 
      <td className='table__td'>
        {/*<button className='gg-delete item__link' onClick={handleDelete}></button>*/}
        <button className='gg-edit item__link' onClick={handleEdit}></button>
        <button className='gg-save item__link' onClick={handleSave}></button>
        <button className='gg-copy item__link' onClick={handleCopy}></button>
      </td>
      
    </tr>
  )
   
}