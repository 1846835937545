import React from 'react';
import { ItemList, TypeFood } from '../'

export function BrandPacking (props) {
  const item = props.item
  
  function handleClick(packing) {
    props.onClick(packing, item)
  }

  return (
    <li className='brands__packing'>
      <ItemList 
        handleDelete={props.handleDelete}
        item={item}
      />
    {item.packing.length>0 &&
      item.packing.map((i)=>(
        <TypeFood 
          item={i}
          onClick={handleClick} 
          name={i.weight}
          id={i.weight}
        />
    ))
    }
  </li>
  )
}