// Добавление в библиотеку данных

import React from 'react';
import './DataBlock.css';
import { postLibrary, deleteLibrary, updateLibrary} from '../../../store/api';
import { useDispatch, useSelector } from 'react-redux'
import { AddButton, ItemEdit, ModalContainer, VitaminCard, BrandCard} from '../../atoms' 

import { OpenModal } from '../../../utils/utils';
import {useFormValidation} from '../../../utils/Validator'
import { vitamins, brands, kitchenware, typeFoods, measure, catIn, typeIn } from '../../../constants/constants';
export function DataBlock (props) {
  const dispatch = useDispatch(); 
  const { lib } = useSelector(state => state.lib)
  const {isOpen, handleOpen, handleClose, editData, setEditData } = OpenModal({})
  const [name, setName] = React.useState(false)
  const [packing, setPacking] = React.useState(1)
  const [arrPacking, setArrPacking] = React.useState(false)
  
  const { 
    values, 
    setValues,
    resetForm,
    handleChange,
  } = useFormValidation({});
 
  React.useEffect(()=> {
    resetForm()
    setArrPacking([])
    if(editData) {
      setValues({...editData})
      setArrPacking(editData.packing)
    }
  }, [isOpen, editData])
 
  function handleSubmit(e) {
    e.preventDefault();
    if(!editData) {
      dispatch(postLibrary({name: props.nameEn, values: {...values, packing: arrPacking}})) 
    } else {
      dispatch(updateLibrary({id: editData._id, name: name, values: {...values, packing: arrPacking} })) 
    }
    handleClose()
  }
   
  function handleDelete(data) {
    dispatch(deleteLibrary({id: data.id, name: data.name })) 
  }

  function handleDeletePacking(item) {
    setArrPacking((arrPacking)=> arrPacking.filter((c) => c.weight !== item))
 
  } 

  function handleEdit(item, name) {
    setName(name)
    setEditData(item)
    handleOpen()
  }

  function handleAddPacking() {
    //setArrPacking((arrPacking) => arrPacking.map((c) => c.nameEn === item.nameEn ? item : c));
    //setArrPacking(arrPacking => [...arrPacking,{ weight: packing, boolean: false}])
    setArrPacking([ ...arrPacking, {weight: packing, boolean: false}]); 
    setPacking(1)
  }
  function handleChangePacking(e) {
    setPacking(+e.target.value)  
  
  }
  
  return (
    <div className={`data-block data-block_type_${props.title}`} >
      <h2 className='data-block__name'>{props.nameRu}</h2>
      <AddButton 
        onClick={handleOpen}
        type=''
        title='Добавить'
      />
      <ul className='add-recipe__vitamin'>
        {lib !== null && Object.keys(lib).length !== 0 &&
        lib[props.nameEn].map((item, index)=> (
          <ItemEdit
            key={index}
            item={item}
            name={props.nameEn}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
      </ul>
      <ModalContainer 
        isOpen={isOpen}
        title='vitamins'
        onClose={handleClose}
        
      > 
      {props.nameEn === 'vitamins' ?
        <VitaminCard 
          values={values}
          handleChange={handleChange}
          onSubmit={handleSubmit}
          arr= {vitamins}
          title={!editData ? 'Сохранить': 'Редактировать'}
        />  
        : props.nameEn === 'brands' ?
        <BrandCard 
        values={values}
        onChange={handleChange}
        handleChangePacking={handleChangePacking}
        handleDelete={handleDeletePacking}
        onSubmit={handleSubmit}
        arr= {brands}
        packing={packing}
        arrPacking={arrPacking}
        onClick={handleAddPacking}
        title={!editData ? 'Сохранить': 'Редактировать'}
      />  
      : props.nameEn === 'typeFoods' ?
      <VitaminCard 
      values={values}
      handleChange={handleChange}
      onSubmit={handleSubmit}
      arr= {typeFoods}
      title={!editData ? 'Сохранить': 'Редактировать'}
    /> : props.nameEn === 'measure' ?
      <VitaminCard 
        values={values}
        handleChange={handleChange}
        onSubmit={handleSubmit}
        arr= {measure}
        title={!editData ? 'Сохранить': 'Редактировать'}
      /> : props.nameEn === 'dish' ?
      <VitaminCard 
        values={values}
        handleChange={handleChange}
        onSubmit={handleSubmit}
        arr= {typeFoods}
        title={!editData ? 'Сохранить': 'Редактировать'}
      />  : props.nameEn === 'catIn' ?
      <VitaminCard 
        values={values}
        handleChange={handleChange}
        onSubmit={handleSubmit}
        arr= {catIn}
        title={!editData ? 'Сохранить': 'Редактировать'}
      /> : props.nameEn === 'kitchenware' ?
      <VitaminCard 
        values={values}
        name='kitchenware'
        handleChange={handleChange}
        onSubmit={handleSubmit}
        arr= {kitchenware}
        title={!editData ? 'Сохранить': 'Редактировать'}
      /> : props.nameEn === 'typeIn' ?
      <VitaminCard 
        values={values}
        name='typeIn'
        handleChange={handleChange}
        onSubmit={handleSubmit}
        arr= {typeIn}
        title={!editData ? 'Сохранить': 'Редактировать'}
      /> : ''
      }
       
      </ModalContainer>
    </div>
  )
}
  