import React from 'react';
 
export function CheckBox (props) {
  const {heat, fresh, frying, thermo} = props
  
  return (
    <> 
    <label className='meals__label' htmlFor='fresh'>
      <input type="radio" checked={thermo === 'fresh' || ''}  className='meals__checkbox' value='fresh' onChange={props.onChange} name='thermo' id='fresh' />
      <span className="visible-checkbox item__link"></span>
      Без термообработки
    </label>
    <label className='meals__label' htmlFor='heat'>
      <input type="radio" value='heat' checked={thermo === 'heat' || ''} className='meals__checkbox' onChange={props.onChange} name='thermo' id='heat' />
      <span className="visible-checkbox item__link"></span>
      Варка
    </label>
    <label className='meals__label' htmlFor='frying'>
      <input type="radio" value='frying' checked={thermo === 'frying' || ''}  className='meals__checkbox' onChange={props.onChange} name='thermo' id='frying' />
      <span className="visible-checkbox item__link"></span>
      Жарка
    </label>
    </>
  )
} 