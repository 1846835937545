import React from 'react';
import Form from '../../organisms/Form/Form'
import './SignUp.css'
import {useFormValidation} from '../../../utils/Validator.js';
import {pattern} from '../../../constants/constants';

function SignUp(props) {
  const submitAuth = props.submitAuth;
  const { values, 
    handleChange,
    isValid,
    emailError, 
    passwordError, 
    nameError,
   } = useFormValidation({
    email: '', password: '' });
  const submitDisabled = values.email === '' || values.password === '' || !isValid || submitAuth;
  
  function handleSubmit(e) {
    e.preventDefault();
    props.onRegister(values.email, values.password, values.name) 
  } 

  return (
    <Form 
    title="Добро пожаловать!" 
    buttonName="Зарегистрироваться" 
    text="Уже зарегистрированы?"
    sign="Войти"
    rout="/signin"
    onRegister={props.onRegister}
    email={values.email}
    password={values.password}
    onSubmit={handleSubmit}
    onChange={handleChange}
    errorsEmail={emailError}
    errorsPassword={passwordError}
    submitDisabled={submitDisabled}
    isOpen={props.isOpen}
    >
    <label className="input__label" htmlFor="name">Имя</label>
    <input 
    onChange={handleChange} 
    autoComplete="off" 
    value={values.name} 
    type="text"
    className="form__item form__item_type_name" 
    name="name" 
    id="name" 
    required 
    minLength="2" 
    maxLength="40"
    pattern={pattern.name}
    />
    {nameError && <span className="email-error form__item-error">{nameError}</span>} 
    </Form>    
  )
}
 
export default SignUp;