import React from 'react';
import './ModalEdit.css'
import { SelectionCategory } from '../../atoms'; 
import { subscriptions } from '../../../constants/constants';

export function ModalEdit (props) {

  return (
    <div className={`modal modal_type_${props.name} ${props.isOpen ? ('modal_opened') : ''}`} >
      <div className={`modal__container modal__container__type_${props.name}`}>   
        <button onClick={props.onClose} type="button" className="modal__close" aria-label="Закрыть форму"></button>
        <form className="form__info" onSubmit={props.onSubmit}> 
          {props.name === 'subscriptions' ?
          <label className='add-recipe__label' htmlFor={props.name} >
            <select 
              value={props.value}  
              onChange={props.onChange} 
              className='add-recipe__input add-recipe__input_category' 
              name={props.name} 
              id={props.name} 
            >
              {subscriptions.map((item, index)=> (
                <SelectionCategory item={item.title} key={index}/>
              ))}
            </select>
          </label>
:           <input 
              autoComplete="off" 
              type={props.type}
              onChange={props.onChange} 
              value={props.value} 
              placeholder="Введите данные" 
              className="form__item form__item_type_email" 
              name={props.name} 
              id={props.name} 
              required
            />
          }
          <button className={`form__button ${props.submitDisabled ? ('form__button_disabled') : 'section__link'}`} type="submit" aria-label='Сохранить'>Сохранить</button> 
        </form>
      </div>
    </div>
  )
}
