//Кнопки выбора типов питания

import React from 'react';

export function TypeFood (props) {
  const item = props.item
  const types = {...item}
  const [values, setValues] = React.useState()
  
  React.useEffect(() => {
    setValues(item.boolean)
  }, [item]);

  function handleClick () {
    setValues(!values)
    types.boolean =!values
    props.onClick(types)
  }
  
  return (
    <li 
      onClick={handleClick} 
      className={`button__type menuweek-button font20Norm ${values && ('button__type_active color')}`}
    >
      {props.item.nameRu}
    </li>
  )
}
